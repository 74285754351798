// grid
$grid-columns:                12;
$grid-gutter-width:           16px;
$enable-grid-classes:         false;
$enable-custom-grid-classes:  true;

$container-max-widths: (
  sm: 540px,
  md: 650px,
  lg: 760px,
  xl: 870px
) !default;
