@import 'variables';

@import '../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../node_modules/bootstrap/scss/bootstrap-grid';
@import '../../node_modules/vanillajs-datepicker/sass/datepicker.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

a {
    &:link,
    &:visited {
        color: white;
        transition: all 150ms ease-in-out;
        text-decoration: underline;
    }

    &:hover {
        color: #eee;
    }
}

.button {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    background-color: #72b84c;
    outline: none;
    border: none;
    display: inline-block;
    padding: .3em 1.5em;
    text-decoration: none;
    transition: all 150ms ease-in-out;

    &:link,
    &:visited {
        text-decoration: none;
    }

    &:hover {
        background-color: darken(#72b84c, 10%);
    }

    body.site-halloween & {
        background-color: #e2241d;
    }
}

.alt-button {
    @extend .button;

    background-color: #0073b9;

    &:hover {
        background-color: darken(#0073b9, 10%);
    }

    body.site-halloween & {
        background-color: #333f48;

        &:hover {
            background-color: darken(#333f48, 10%);
        }
    }

    body.site-sinterklaas & {
        background-color: #e2241d;

        &:hover {
            background-color: darken(#e2241d, 10%);
        }
    }
}

body {
    background-color: #eee;
    font-family: 'Lato', sans-serif;

    font-size: 20px;
    line-height: 1.5;

    color: #343f47;

}

img {
    display: block;
    max-width: 100%;
}

.outer-container {
    max-width: 1600px;
    margin: 0 auto;
    background: white;
    min-height: 100vh;
}

.container {
    @include make-container;
    @include make-container-max-widths();

    position: relative;
    z-index: 1;
}

h2 {
    span {
        color: #72b84c;

        body.site-sinterklaas & {
            color: #e2241d;
        }
    }
}

header {
    background: url('../img/head.png') no-repeat center center;
    background-size: cover;
    position: relative;

    body.site-sinterklaas & {
        background: none;
    }

    .logo {
        position: absolute;
        right: 0;
        top: 60px;
        max-width: 90%;
    }

    .info {
        padding-bottom: 3em;
    }

    @include media-breakpoint-down(lg) {
        min-height: 450px;
    }

    @include media-breakpoint-down(md) {
        min-height: 300px;

        .logo {
            top: 50%;
            transform: translateY(-50%);

            img {
                max-height: 100px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        min-height: 200px;
    }
}

#form {
    background: url('../img/form_bg.png') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 3em 0;
    position: relative;
    display: block;

    &.summer-form {
        background: none;
        background-color: #72b84c;

        legend {
            background-color: #000;
            padding: .5em .8em;
        }

        button {
            background-color: #e2241d;

            &:hover {
                background-color: darken(#e2241d, 10%);
            }
        }
    }

    body.site-halloween & {
        background: black;

        &:after,
        &:before {
            content: "";
            display: block;
            position: absolute;
            height: 865px;
            z-index: 0;
            top: 0;
            pointer-events: none;
        }

        &:before {
            background-image: url('../img/form_before.png');
            width: 455px;
            left: 0;
        }

        &:after {
            background-image: url('../img/form_after.png');
            width: 546px;
            right: 0;
        }

        @include media-breakpoint-down(md) {
            &:after,
            &:before {
                width: 100px;
                height: 150px;
                background-size: contain
            }

            &:before { display: none; }
        }
    }

    body.site-sinterklaas & {
        background: url('../img/form_bg.jpg') repeat center center;
        background-size: auto;
        background-color: #78d9e9;
        color: #000;

        a {
            color: #000;

            &.alt-button {
                color: #fff;
            }
        }

        &:before {
            background-image: url('../img/form_before.png');
            width: 455px;
            left: 0;
        }

        &:after {
            background-image: url('../img/form_after.png');
            width: 546px;
            right: 0;
        }

        @include media-breakpoint-down(md) {
            &:after,
            &:before {
                width: 100px;
                height: 150px;
                background-size: contain
            }

            &:before { display: none; }
        }

        button {
            background-color: #000;

            &:hover {
                background-color: lighten(#000, 15%);
            }
        }
    }

    .remark {
        font-size: .9em;
        padding: 2em 0 .5em;

        body.site-sinterklaas & {
            text-align: right;
        }
    }

    fieldset {
        border: 2px solid white;
        padding: 1em 3em 0 3em;

        legend {
            width: auto;
            padding: 0 .5em;
            text-transform: uppercase;
            color: white;
        }

        @include media-breakpoint-down(md) {
            border: none;
            padding: 1em;
        }

        body.site-sinterklaas & {
            border: none;
            background-color: rgba(255, 255, 255, .5);
        }
    }

    .input {
        &.select,
        &.text,
        &.number,
        &.email,
        &.textarea {
            padding: .4em 0;

            select,
            textarea,
            input {
                font-family: 'Lato', sans-serif;
                padding: .2em 1em;
                width: 100%;
                border: none;
                background-color: #fff;
                height: 38px;

                &#time {
                    &:invalid {
                        color: rgba(0, 0, 0, .5);
                    }
                    &:valid {
                        color: #000;
                    }
                }
            }
        }
    }

    .split {
        @include make-row;

        padding: .4em 0;

        .input {
            &.select,
            &.text,
            &.number,
            &.email,
            &.textarea {
                padding: 0;
                @include make-col-ready;
                @include make-col(6);

                &.street {
                    @include make-col(8);
                }
                &.number {
                    @include make-col(4);
                }
            }
        }

        &.nine-three {
            .input {
                &:first-of-type {
                    @include make-col(9);
                }
                &:last-of-type {
                    @include make-col(3);
                }
            }
        }
    }

    .action {
        padding-top: 2em;
        text-align: center;
    }

    button {
        @extend .button;
    }
}

#intro-clean {
    padding: 5em 0;
}

.heading {
    position: relative;
    max-width: 540px;

    .pad-btm {
        margin-bottom: 1rem;
    }

    .quote {
        display: block;
        position: absolute;
        width: 67px;
        z-index: 0;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &.start {
            left: -60px;
            top: -33px;
        }

        &.end {
            right: -66px;
            bottom: -33px;

            @include media-breakpoint-down(md) {
                right: -66px;
                bottom: -33px;
            }
        }
    }
}

#intro,
#outro-summer {
    @include make-row;

    padding: 5em 0;
    background: url('../img/pagevis.png') no-repeat center right;
    background-size: auto 80%;

    .content {
        @include make-col-ready;
        @include make-col(8);
    }

    @include media-breakpoint-down(lg) {
        background: none;
        padding: 3em 1em;

        .content {
            @include make-col(12);
        }
    }
}
#intro-summer {
    @include make-row;

    padding: 2em 0 2em;
    margin-top: 3em;
    background: url('../img/picknick.jpg') no-repeat center right;
    background-size: auto 80%;

    &.thanks {
        padding-bottom: 2em;
        margin-bottom: 3em;
    }

    .content {
        @include make-col-ready;
        @include make-col(7);
    }

    @include media-breakpoint-down(lg) {
        background: none;
        padding: 0 1em 2em;

        .content {
            @include make-col(12);
        }
    }
}
#outro-summer {
    background: none;
    padding-top: 0em;

    .content {
        @include make-col(12);

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}


.sub-info {
    text-align: center;
    padding-top: 2em;

    h3,
    p {
        font-weight: normal;
        margin: 0;
        padding: 1em 0;

        body.site-sinterklaas & {
            padding-bottom: 0;
        }
    }
}

.sub-img {
    background-color: #fff;
    padding: 1em 4em;
    text-align: right;

    img {
        max-width: 80%;
        display: inline-block;
    }
}


footer {
    background-color: white;
    padding: 2em 0;
    border-top: 2px solid white;
    text-align: center;

    &.green {
        background-color: #72b84c;
    }

    img {
        display: inline-block;

        @include media-breakpoint-down(md) {
            max-width: 50%;
        }

        &.logo {
            padding: 0 2rem;
            max-height: 40px;

            @include media-breakpoint-down(md) {
                max-width: 45%;
                display: block;
                margin: 0 auto 2rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
